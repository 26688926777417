import React, { useEffect } from 'react'
import * as s from "./main.module.css"

// import useSize from '../utils/useSize';

import { gsap } from "gsap/dist/gsap";

import "animate.css/animate.min.css";

import Icon from '../assets/article.svg';

import { withpath } from '../utils/index.js'

import Art0 from '../assets/art0-new.svg';
import Art1 from '../assets/art1-new.svg';
import Art2 from '../assets/art2-new.svg';
import Art3 from '../assets/art3-new.svg';
import Art4 from '../assets/art4-new.svg';
import Art5 from '../assets/art5-new.svg';
import Art6 from '../assets/art6-new.svg';

import { Waypoint } from 'react-waypoint';

const Main = (p) => {

	const data = p.data
  const waypercent = "20%"
  const waypercent2 = "50%"
  let vdistance = 30

	gsap.config({
		autoSleep: 60,
		force3D: false,
		nullTargetWarn: false
	});

	useEffect(() => {

    let person1img = document.querySelectorAll(`#person1-img`);
    let person1name = document.querySelectorAll(`#person1-name`);
    let person2img = document.querySelectorAll(`#person2-img`);
    let person2name = document.querySelectorAll(`#person2-name`);
    gsap.killTweensOf([person1img,person1name,person2img,person2name]);
		gsap.set(person1img, {opacity: 0, scale: 0.8, transformOrigin: "50% 50%"})
		gsap.set(person1name, {opacity: 0, scale: 0.8, transformOrigin: "50% 50%"})
    gsap.set(person2img, {opacity: 0, scale: 0.8, transformOrigin: "50% 50%"})
    gsap.set(person2name, {opacity: 0, scale: 0.8, transformOrigin: "50% 50%"})
    
		let tl = gsap.timeline({ paused: false });
			tl.to(person1img, {opacity: 1, scale: 1, translateY: 0, duration: 1, ease: `power1.out` }, 0.0);
			tl.to(person1name, {opacity: 1, scale: 1, translateY: 0, duration: 1, ease: `power1.out` }, 0.3);
			tl.to(person2img, {opacity: 1, scale: 1, translateY: 0, duration: 1, ease: `power1.out` }, 0.7);
      tl.to(person2name, {opacity: 1, scale: 1, translateY: 0, duration: 1, ease: `power1.out` }, 1.0);

		let art0txt1 = document.querySelectorAll(`#art0-txt1`);
		let art0raised = document.querySelectorAll(`#art0-raised`);
		let art0txt3 = document.querySelectorAll(`#art0-txt3`);
		let art0deals = document.querySelectorAll(`#art0-deals`);
		let art0txt5 = document.querySelectorAll(`#art0-txt5`);
		gsap.killTweensOf([art0txt1,art0raised,art0txt3,art0deals,art0txt5]);
		gsap.set(art0txt1, {opacity: 0, transformOrigin: "50% 50%"})
		gsap.set(art0raised, {opacity: 0, transformOrigin: "50% 50%"})
    gsap.set(art0txt3, {opacity: 0, transformOrigin: "50% 50%"})
    gsap.set(art0deals, {opacity: 0, transformOrigin: "50% 50%"})
		gsap.set(art0txt5, {opacity: 0, transformOrigin: "50% 50%"})

		let art1bg = document.querySelectorAll(`#art1-bg`);
		let art1triangle = document.querySelectorAll(`#art1-triangle`);
		let art1circle = document.querySelectorAll(`#art1-circle`);
		let art1arrow = document.querySelectorAll(`#art1-arrow`);
		let art1txt1 = document.querySelectorAll(`#art1-txt1`);
		let art1txt2 = document.querySelectorAll(`#art1-txt2`);
		let art1txt3 = document.querySelectorAll(`#art1-txt3`);
		gsap.killTweensOf([art1bg,art1triangle,art1circle,art1arrow,art1txt1,art1txt2,art1txt3]);
		gsap.set(art1bg, {opacity: 0, y:40})
		gsap.set(art1triangle, {opacity: 0})
		gsap.set(art1circle, {opacity: 0})
		gsap.set(art1arrow, {opacity: 0})
		gsap.set(art1txt1, {opacity: 0, y: 20})
		gsap.set(art1txt2, {opacity: 0, y: 20})
		gsap.set(art1txt3, {opacity: 0, y: 20})

		let art2bg = document.querySelectorAll(`#art2-bg`);
		let art2square = document.querySelectorAll(`#art2-square1`);
		let art2cog1 = document.querySelectorAll(`#art2-cog1`);
		let art2cog2 = document.querySelectorAll(`#art2-cog2`);
		let art2bar = document.querySelectorAll(`#art2-bar`);
		let art2txt1 = document.querySelectorAll(`#art2-txt1`);
		let art2txt2 = document.querySelectorAll(`#art2-txt2`);
		let art2txt3 = document.querySelectorAll(`#art2-txt3`);
		gsap.killTweensOf([art2bg,art2square,art2cog1,art2cog2,art2bar,art2txt1,art2txt2,art2txt3]);
		gsap.set([art2bg,art2square,art2cog1,art2cog2,art2bar,art2txt1,art2txt2,art2txt3], {opacity: 0})

		let art3circle1 = document.querySelectorAll(`#art3-circle1`);
		let art3circle2 = document.querySelectorAll(`#art3-circle2`);
		let art3pill1 = document.querySelectorAll(`#art3-pill1`);
		let art3circle3 = document.querySelectorAll(`#art3-circle3`);
		let art3circle4 = document.querySelectorAll(`#art3-circle4`);
		gsap.killTweensOf([art3circle1,art3circle2,art3pill1,art3circle3,art3circle4]);
    gsap.set([art3circle1,art3circle2,art3pill1,art3circle3,art3circle4], {opacity: 0});
    

    let art4txt1 = document.querySelectorAll(`#art4-txt1`);
		let art4txt2 = document.querySelectorAll(`#art4-txt2`);
		let art4txt3 = document.querySelectorAll(`#art4-txt3`);
		let art4txt4 = document.querySelectorAll(`#art4-txt4`);
		let art4txt5 = document.querySelectorAll(`#art4-txt5`);
		gsap.killTweensOf([art4txt1,art4txt2,art4txt3,art4txt4,art4txt5]);
    gsap.set([art4txt1,art4txt2,art4txt3,art4txt4,art4txt5], {opacity: 0});


    let art5circle1 = document.querySelectorAll(`#art5-circle1`);
		let art5pill1 = document.querySelectorAll(`#art5-pill1`);
		gsap.killTweensOf([art5circle1,art5pill1]);
    gsap.set([art5circle1,art5pill1], {opacity: 0});
    

    let art6txt1 = document.querySelectorAll(`#art6-txt1`);
		let art6txt2 = document.querySelectorAll(`#art6-txt2`);
		let art6txt3 = document.querySelectorAll(`#art6-txt3`);
		gsap.killTweensOf([art6txt1,art6txt2,art6txt3]);
    gsap.set([art6txt1,art6txt2,art6txt3], {opacity: 0});

  },[])
  

  /* function enterP(){
		
	}

	function leaveP(){
		let person1img = document.querySelectorAll(`#person1-img`);
    let person1name = document.querySelectorAll(`#person1-name`);
    let person2img = document.querySelectorAll(`#person2-img`);
    let person2name = document.querySelectorAll(`#person2-name`);

		gsap.killTweensOf([person1img,person1name,person2img,person2name]);

    let tl = gsap.timeline({ paused: false });
      tl.to([person1img,person1name,person2img,person2name], {opacity: 0, translateY: -20, duration: .4, stagger: .07, ease: `power1.out` }, 0.0);
	} */


	function enter0(){
		let art0txt1 = document.querySelectorAll(`#art0-txt1`);
		let art0raised = document.querySelectorAll(`#art0-raised`);
		let art0txt3 = document.querySelectorAll(`#art0-txt3`);
		let art0deals = document.querySelectorAll(`#art0-deals`);
    let art0txt5 = document.querySelectorAll(`#art0-txt5`);
    
		gsap.killTweensOf([art0txt1,art0raised,art0txt3,art0deals,art0txt5]);
		gsap.set(art0txt1, {opacity: 0, translateY: vdistance, transformOrigin: "50% 50%"})
		gsap.set(art0raised, {opacity: 0, translateY: vdistance, transformOrigin: "50% 50%"})
    gsap.set(art0txt3, {opacity: 0, translateY: vdistance, transformOrigin: "50% 50%"})
    gsap.set(art0deals, {opacity: 0, translateY: vdistance, transformOrigin: "50% 50%"})
		gsap.set(art0txt5, {opacity: 0, translateY: vdistance, transformOrigin: "50% 50%"})

		let tl = gsap.timeline({ paused: false });
			tl.to(art0txt1, {opacity: 1, translateY: 0, duration: 1, ease: `power1.out` }, 0.0);
			tl.to(art0raised, {opacity: 1, translateY: 0, duration: 1, ease: `power1.out` }, 0.3);
			tl.to(art0txt3, {opacity: 1, translateY: 0, duration: 1, ease: `power1.out` }, 0.9);
      tl.to(art0deals, {opacity: 1, translateY: 0, duration: 1, ease: `power1.out` }, 1.2);
      tl.to(art0txt5, {opacity: 1, translateY: 0, duration: 1, ease: `power1.out` }, 1.5);
	}

	function leave0(){
		let art0txt1 = document.querySelectorAll(`#art0-txt1`);
		let art0raised = document.querySelectorAll(`#art0-raised`);
		let art0txt3 = document.querySelectorAll(`#art0-txt3`);
		let art0deals = document.querySelectorAll(`#art0-deals`);
    let art0txt5 = document.querySelectorAll(`#art0-txt5`);

		gsap.killTweensOf([art0txt1,art0raised,art0txt3,art0deals,art0txt5]);

    let tl = gsap.timeline({ paused: false });
      tl.to([art0txt1, art0raised, art0txt3, art0deals, art0txt5], {opacity: 0, duration: .2, stagger: .05, ease: `power1.out` }, 0.0);
	}


	function enter1(){
		let art1bg = document.querySelectorAll(`#art1-bg`);
		let art1triangle = document.querySelectorAll(`#art1-triangle`);
		let art1circle = document.querySelectorAll(`#art1-circle`);
		let art1arrow = document.querySelectorAll(`#art1-arrow`);
		let art1txt1 = document.querySelectorAll(`#art1-txt1`);
		let art1txt2 = document.querySelectorAll(`#art1-txt2`);
    let art1txt3 = document.querySelectorAll(`#art1-txt3`);
    
    gsap.killTweensOf([art1bg,art1triangle,art1circle,art1arrow,art1txt1,art1txt2,art1txt3]);
    
		gsap.set(art1bg, {opacity: 0, translateY: 40})
		gsap.set(art1triangle, {translateY: vdistance, opacity: 0})
		gsap.set(art1circle, {translateY: vdistance, opacity: 0})
		gsap.set(art1arrow, {opacity: 0, scaleY: .3, transformOrigin: "bottom center"})
		gsap.set(art1txt1, {opacity: 0, translateY: vdistance})
		gsap.set(art1txt2, {opacity: 0, translateY: vdistance})
		gsap.set(art1txt3, {opacity: 0, translateY: vdistance})

		let tl = gsap.timeline({ paused: false });
			tl.to(art1bg, {opacity: 1, duration: 2, translateY: 0, ease: `power1.out` }, 0.0);
			tl.to(art1triangle, {opacity: 1, translateY: 0, duration: 1, transformOrigin: "50% 50%", ease: `power1.out` }, 0.1);
			tl.to(art1circle, {opacity: 1, translateY: 0, duration: 1, transformOrigin: "50% 50%", ease: `power1.out` }, 0.3);
			tl.to(art1arrow, {opacity: 1, scaleY: 1, duration: 2, transformOrigin: "bottom center", ease: `power1.out` }, 0.8);
      tl.to(art1txt1, {opacity: 1, translateY: 0, duration: 1, transformOrigin: "50% 50%", ease: `power1.out` }, 1.0);
      tl.to(art1txt2, {opacity: 1, translateY: 0, duration: 1, transformOrigin: "50% 50%", ease: `power1.out` }, 1.0);
      tl.to(art1txt3, {opacity: 1, translateY: 0, duration: 1, transformOrigin: "50% 50%", ease: `power1.out` }, 1.0);
	}

	function leave1(){
		let art1bg = document.querySelectorAll(`#art1-bg`);
		let art1triangle = document.querySelectorAll(`#art1-triangle`);
		let art1circle = document.querySelectorAll(`#art1-circle`);
		let art1arrow = document.querySelectorAll(`#art1-arrow`);
		let art1txt1 = document.querySelectorAll(`#art1-txt1`);
		let art1txt2 = document.querySelectorAll(`#art1-txt2`);
    let art1txt3 = document.querySelectorAll(`#art1-txt3`);

		gsap.killTweensOf([art1bg,art1triangle,art1circle,art1arrow,art1txt1,art1txt2,art1txt3]);

		let tl = gsap.timeline({ paused: false });
    tl.to([art1bg, art1triangle, art1circle, art1arrow, art1txt1, art1txt2, art1txt3], {opacity: 0, duration: .2, stagger: .05, ease: `power1.out` }, 0.0);
	}


	function enter2(){
		let art2bg = document.querySelectorAll(`#art2-bg`);
		let art2square = document.querySelectorAll(`#art2-square1`);
		let art2cog1 = document.querySelectorAll(`#art2-cog1`);
		let art2cog2 = document.querySelectorAll(`#art2-cog2`);
		let art2bar = document.querySelectorAll(`#art2-bar`);
		let art2txt1 = document.querySelectorAll(`#art2-txt1`);
		let art2txt2 = document.querySelectorAll(`#art2-txt2`);
    let art2txt3 = document.querySelectorAll(`#art2-txt3`);

		gsap.killTweensOf([art2bg,art2square,art2cog1,art2cog2,art2bar,art2txt1,art2txt2,art2txt3]);
    gsap.set([art2bg,art2square,art2cog1,art2cog2,art2bar,art2txt1,art2txt2,art2txt3], {opacity: 0, translateY: vdistance})
    gsap.set(art2cog1, {translateX: 40, translateY: -50, rotate: 30})
    gsap.set(art2cog2, {translateX: -40, translateY: -20, rotate: -20})

		let tl = gsap.timeline({ paused: false });
			tl.to(art2bg, {opacity: 1, translateY: 0, duration: 1, ease: `power1.out`}, 0.0);
			tl.to(art2square, {opacity: 1, translateY: 0, duration: 1, ease: `power1.out`}, 0.8);
			tl.to(art2cog1, {opacity: 1, translateY: -20, rotate: 0, duration: 1, transformOrigin: "50% 50%", ease: `power1.out`}, 1.0);
      tl.to(art2cog2, {opacity: 1, translateY: 30, rotate: 0, duration: 1, transformOrigin: "50% 50%",ease: `power1.out`}, 1.2);
      tl.to(art2bar, {opacity: 1, translateY: 0, duration: 1, ease: `power1.out`}, 1.2);
      tl.to(art2txt1, {opacity: 1, translateY: 0, duration: 1, ease: `power1.out`}, 1.25);
      tl.to(art2txt2, {opacity: 1, translateY: 0, duration: 1, ease: `power1.out`}, 1.35);
      tl.to(art2txt3, {opacity: 1, translateY: 0, duration: 1, ease: `power1.out`}, 1.45);
      
	}

	function leave2(){
		let art2bg = document.querySelectorAll(`#art2-bg`);
		let art2square = document.querySelectorAll(`#art2-square1`);
		let art2cog1 = document.querySelectorAll(`#art2-cog1`);
		let art2cog2 = document.querySelectorAll(`#art2-cog2`);
		let art2bar = document.querySelectorAll(`#art2-bar`);
		let art2txt1 = document.querySelectorAll(`#art2-txt1`);
		let art2txt2 = document.querySelectorAll(`#art2-txt2`);
    let art2txt3 = document.querySelectorAll(`#art2-txt3`);

    gsap.killTweensOf([art2bg,art2square,art2cog1,art2cog2,art2bar,art2txt1,art2txt2,art2txt3]);

		let tl = gsap.timeline({ paused: false });
			tl.to([art2bg,art2square,art2cog1,art2cog2,art2bar,art2txt1,art2txt2,art2txt3], {opacity: 0, duration: .2, stagger: .05, ease: `power1.out` }, 0.0);
	}


	function enter3(){
		let art3circle1 = document.querySelectorAll(`#art3-circle1`);
		let art3circle2 = document.querySelectorAll(`#art3-circle2`);
		let art3pill1 = document.querySelectorAll(`#art3-pill1`);
		let art3circle3 = document.querySelectorAll(`#art3-circle3`);
    let art3circle4 = document.querySelectorAll(`#art3-circle4`);

		gsap.killTweensOf([art3circle1,art3circle2,art3pill1,art3circle3,art3circle4]);
    gsap.set([art3circle1,art3circle2,art3pill1,art3circle3,art3circle4], {opacity: 0, translateY: vdistance});
    
		let tl = gsap.timeline({ paused: false });
			tl.to(art3circle1, {opacity: 1, translateY: 0, duration: 1, ease: `power1.out` }, 0.0);
			tl.to(art3circle2, {opacity: 1, translateY: 0, duration: 1, ease: `power1.out` }, 0.0);
			tl.to(art3pill1, {opacity: 1, translateY: 0, duration: 1, ease: `power1.out` }, 0.3);
			tl.to(art3circle3, {opacity: 1, translateY: 0, duration: 1, ease: `power1.out` }, 0.5);
      tl.to(art3circle4, {opacity: 1, translateY: 0, duration: 1, ease: `power1.out` }, 1.0);
	}

	function leave3(){
		let art3circle1 = document.querySelectorAll(`#art3-circle1`);
		let art3circle2 = document.querySelectorAll(`#art3-circle2`);
		let art3pill1 = document.querySelectorAll(`#art3-pill1`);
		let art3circle3 = document.querySelectorAll(`#art3-circle3`);
    let art3circle4 = document.querySelectorAll(`#art3-circle4`);

		gsap.killTweensOf([art3circle1,art3circle2,art3pill1,art3circle3,art3circle4]);

		let tl = gsap.timeline({ paused: false });
    tl.to([art3circle1,art3circle2,art3pill1,art3circle3,art3circle4], {opacity: 0, duration: .2, stagger: .08, ease: `power1.out` }, 0.0);
  }


  function enter4(){
    let art4txt1 = document.querySelectorAll(`#art4-txt1`);
    let art4txt2 = document.querySelectorAll(`#art4-txt2`);
    let art4txt3 = document.querySelectorAll(`#art4-txt3`);
    let art4txt4 = document.querySelectorAll(`#art4-txt4`);
    let art4txt5 = document.querySelectorAll(`#art4-txt5`);
    
    gsap.killTweensOf([art4txt1,art4txt2,art4txt3,art4txt4,art4txt5]);
    gsap.set([art4txt1,art4txt2,art4txt3,art4txt4,art4txt5], {opacity: 0, translateY: vdistance});
    

		let tl = gsap.timeline({ paused: false });
      tl.to(art4txt1, {opacity: 1, translateY: 0, duration: 1, ease: `power1.out` });
      tl.to(art4txt2, {opacity: 1, translateY: 0, duration: 1, ease: `power1.out` }, 0.2);
      tl.to(art4txt3, {opacity: 1, translateY: 0, duration: 1, ease: `power1.out` }, 0.3);
      tl.to(art4txt4, {opacity: 1, translateY: 0, duration: 1, ease: `power1.out` }, 0.4);
      tl.to(art4txt5, {opacity: 1, translateY: 0, duration: 1, ease: `power1.out` }, 0.5);
	}

	function leave4(){
		let art4txt1 = document.querySelectorAll(`#art4-txt1`);
    let art4txt2 = document.querySelectorAll(`#art4-txt2`);
    let art4txt3 = document.querySelectorAll(`#art4-txt3`);
    let art4txt4 = document.querySelectorAll(`#art4-txt4`);
    let art4txt5 = document.querySelectorAll(`#art4-txt5`);

    gsap.killTweensOf([art4txt1,art4txt2,art4txt3,art4txt4,art4txt5]);

		let tl = gsap.timeline({ paused: false });
    tl.to([art4txt1,art4txt2,art4txt3,art4txt4,art4txt5], {opacity: 0, duration: .2, stagger: .08, ease: `power1.out` }, 0.0);
  }


  function enter5(){
    let art5circle1 = document.querySelectorAll(`#art5-circle1`);
		let art5pill1 = document.querySelectorAll(`#art5-pill1`);
    
    gsap.killTweensOf([art5circle1,art5pill1]);
    gsap.set([art5circle1,art5pill1], {opacity: 0, translateY: vdistance});

		let tl = gsap.timeline({ paused: false });
      tl.to(art5circle1, {opacity: 1, translateY: 0, duration: 1, ease: `power1.out` });
      tl.to(art5pill1, {opacity: 1, translateY: 0, duration: 1, ease: `power1.out` }, .2);
  }
  
  function leave5(){
		let art5circle1 = document.querySelectorAll(`#art5-circle1`);
		let art5pill1 = document.querySelectorAll(`#art5-pill1`);
    
    gsap.killTweensOf([art5circle1,art5pill1]);
    
    let tl = gsap.timeline({ paused: false });
    tl.to([art5circle1,art5pill1], {opacity: 0, duration: .2, stagger: .08, ease: `power1.out` }, 0.0);
  }


  function enter6(){
    let art6txt1 = document.querySelectorAll(`#art6-txt1`);
		let art6txt2 = document.querySelectorAll(`#art6-txt2`);
		let art6txt3 = document.querySelectorAll(`#art6-txt3`);

    gsap.killTweensOf([art6txt1,art6txt2,art6txt3]);
    gsap.set([art6txt1,art6txt2,art6txt3], {opacity: 0, translateY: vdistance});

		let tl = gsap.timeline({ paused: false });
      tl.to(art6txt1, {opacity: 1, translateY: 0, duration: 1, ease: `power1.out` });
      tl.to(art6txt2, {opacity: 1, translateY: 0, duration: 1, ease: `power1.out` }, .2);
      tl.to(art6txt3, {opacity: 1, translateY: 0, duration: 1, ease: `power1.out` }, .4);
  }
  
  function leave6(){
		let art6txt1 = document.querySelectorAll(`#art6-txt1`);
		let art6txt2 = document.querySelectorAll(`#art6-txt2`);
		let art6txt3 = document.querySelectorAll(`#art6-txt3`);
    
    gsap.killTweensOf([art6txt1,art6txt2,art6txt3]);

    let tl = gsap.timeline({ paused: false });
    tl.to([art6txt1,art6txt2,art6txt3], {opacity: 0, duration: .2, stagger: .08, ease: `power1.out` }, 0.0);
  }
  


	return (
		<section className={s.article} >

      {/* Hero Block */}
			<div 
				className={s.headerbg} 
				style={{backgroundImage: `url(${withpath(`/img/bg2.jpg`)})` }}
			>
				<div className={s.svgbg}
					style={{backgroundImage: `url(${withpath(`/img/cover.svg`)})` }}
				/>
				<div className={s.headerbox}>
					<div className={s.hicon}>
						<Icon />
					</div>
					<div className={s.h0} dangerouslySetInnerHTML={{__html: data.h0 }} />
					<div className={s.desc} dangerouslySetInnerHTML={{__html: data.desc }} />
				</div>
			</div>

      {/* Intro */}
			<div className={s.box} data-id={0}>

				<div className={s.tbox} data-id={0}>
					<div className={s.tboxl} data-id={0}>

						<div className={s.persons}>
              <div className={s.person}>
                <img id="person1-img" src={withpath(`/img/evelyn_yeo.jpg`)} alt="Evelyn Yao portrait" />
                <p id="person1-name">Evelyn Yeo,<br/>
                Head of Investments in Asia<br/>
                at Pictet Wealth Management</p>
              </div>
              <div className={s.person}>
                <img id="person2-img" src={withpath(`/img/dong_chen.jpg`)} alt="Dong Chen portrait" />
                <p id="person2-name">Dong Chen,<br/>
                Senior Asia Economist<br/>
                at Pictet Wealth Management</p>
              </div>
						</div>

					</div>
					<div className={s.tboxr} data-id={0}>
            {/* <p id="testxt">A test sentence for evaluation.</p> */}
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[0] }} data-id={0} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[1] }} data-id={1} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[2] }} data-id={2} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[3] }} data-id={3} />
					</div>
				</div>

        {/* Electric Wehicles */}
				<div className={s.tbox} data-id={1}>

					<div className={s.tboxl} data-id={1}>
            <div className={s.htxt} dangerouslySetInnerHTML={{__html: data.txt[4] }} data-id={0} />
						<div className={s.hr} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[5] }} data-id={5} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[6] }} data-id={6} />
					</div>
					<div className={s.tboxr} data-id={1}>
          <Waypoint 
							onEnter={(p) => {enter0()}} 
							onLeave={(p) => {leave0()}} 
							bottomOffset={waypercent} 
							scrollableAncestor={`window`} 
							fireOnRapidScroll={false}
						>
						<div className={s.art0}>
							<Art0 />
						</div>
						</Waypoint>
					</div>
				</div>

			</div>

      {/* Reserach and Development */}
			<div className={s.box} data-id={1}>

				<div className={s.tbox} data-id={2}>
					<div className={s.tboxl} data-id={2}>
            <Waypoint 
              onEnter={(p) => {enter1()}} 
              onLeave={(p) => {leave1()}} 
              bottomOffset={waypercent} 
              scrollableAncestor={`window`} 
              fireOnRapidScroll={false}
            >
              <div className={s.art1}>
                <Art1 />
              </div>
            </Waypoint>
					</div>
					<div className={s.tboxr} data-id={2}>
            <div className={s.htxt} dangerouslySetInnerHTML={{__html: data.txt[7] }} data-id={8} />
						<div className={s.hr} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[8] }} data-id={8} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[9] }} data-id={9} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[10] }} data-id={10} />
					</div>
				</div>
			</div>

      {/* Research & Development Part II */}
			<div className={s.box} data-id={2}>
				
				<div className={s.tbox} data-id={3}>
					<div className={s.tboxl} data-id={3}>
          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[11] }} data-id={11} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[12] }} data-id={12} />
					</div>
					<div className={s.tboxr} data-id={3}>
							<Waypoint 
								onEnter={(p) => {enter2()}} 
								onLeave={(p) => {leave2()}} 
								bottomOffset={waypercent} 
								scrollableAncestor={`window`} 
								fireOnRapidScroll={false}
							>
								<div className={s.art2}>
									<Art2 />
								</div>
							</Waypoint>
					</div>
				</div>
			</div>


      {/* Healthcare */}
			<div className={s.box} data-id={3}>
				<div className={s.tbox} data-id={4}>
					<div className={s.tboxl} data-id={4}>
            <div className={`${s.multiart} ${s.multiartDesktop}`}>
							<Waypoint 
								onEnter={(p) => {enter3()}} 
								onLeave={(p) => {leave3()}} 
								bottomOffset={waypercent} 
								scrollableAncestor={`window`} 
								fireOnRapidScroll={false}
							>
								<div className={s.art3}>
									<Art3 />
								</div>
							</Waypoint>
              
              <Waypoint 
								onEnter={(p) => {enter4()}} 
								onLeave={(p) => {leave4()}} 
								bottomOffset={waypercent} 
								scrollableAncestor={`window`} 
								fireOnRapidScroll={false}
							>
								<div className={s.art4}>
									<Art4 />
								</div>
							</Waypoint>

              <Waypoint 
								onEnter={(p) => {enter5()}} 
								onLeave={(p) => {leave5()}} 
								bottomOffset={waypercent} 
								scrollableAncestor={`window`} 
								fireOnRapidScroll={false}
							>
								<div className={s.art5}>
									<Art5 />
								</div>
							</Waypoint>

              <Waypoint 
								onEnter={(p) => {enter6()}} 
								onLeave={(p) => {leave6()}} 
								bottomOffset={waypercent} 
								scrollableAncestor={`window`} 
								fireOnRapidScroll={false}
							>
								<div className={s.art6}>
									<Art6 />
								</div>
							</Waypoint>
            </div>
					</div>
					<div className={s.tboxr} data-id={4}>
            <div className={s.htxt} dangerouslySetInnerHTML={{__html: data.txt[13] }} data-id={13} />
						<div className={s.hr} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[14] }} data-id={14} />
            <div className={`${s.multiart} ${s.multiartMobile}`}>
							<Waypoint 
								onEnter={(p) => {enter3()}} 
								onLeave={(p) => {leave3()}} 
								bottomOffset={waypercent} 
								scrollableAncestor={`window`} 
								fireOnRapidScroll={false}
							>
								<div className={s.art3}>
									<Art3 />
								</div>
							</Waypoint>
              
              <Waypoint 
								onEnter={(p) => {enter4()}} 
								onLeave={(p) => {leave4()}} 
								bottomOffset={waypercent} 
								scrollableAncestor={`window`} 
								fireOnRapidScroll={false}
							>
								<div className={s.art4}>
									<Art4 />
								</div>
							</Waypoint>

              <Waypoint 
								onEnter={(p) => {enter5()}} 
								onLeave={(p) => {leave5()}} 
								bottomOffset={waypercent} 
								scrollableAncestor={`window`} 
								fireOnRapidScroll={false}
							>
								<div className={s.art5}>
									<Art5 />
								</div>
							</Waypoint>

              <Waypoint 
								onEnter={(p) => {enter6()}} 
								onLeave={(p) => {leave6()}} 
								bottomOffset={waypercent} 
								scrollableAncestor={`window`} 
								fireOnRapidScroll={false}
							>
								<div className={s.art6}>
									<Art6 />
								</div>
							</Waypoint>
            </div>
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[15] }} data-id={15} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[16] }} data-id={16} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[17] }} data-id={17} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[18] }} data-id={18} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[19] }} data-id={19} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[20] }} data-id={20} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[21] }} data-id={21} />
					</div>
				</div>
			</div>

{/*
			<div className={s.box} data-id={4}>
				<div className={s.tbox} data-id={5}>
					<div className={s.tboxl} data-id={5}>
						<div className={s.htxt} dangerouslySetInnerHTML={{__html: data.txt[25] }} data-id={25} />
						<div className={s.hr} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[26] }} data-id={26} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[27] }} data-id={27} />
					</div>
					<div className={s.tboxr} data-id={5}>
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[28] }} data-id={28} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[29] }} data-id={29} />
					</div>
				</div>


				<div className={s.tbox} data-id={6}>
					<div className={s.sources} dangerouslySetInnerHTML={{__html: `Sources:`}} />
					<a href={data.sources[0]} target={`_blank`} className={s.source} dangerouslySetInnerHTML={{__html: `1. ` + data.sources[0] }} />
					<a href={data.sources[1]} target={`_blank`} className={s.source} dangerouslySetInnerHTML={{__html: `2. ` + data.sources[1] }} />
					<a href={data.sources[2]} target={`_blank`} className={s.source} dangerouslySetInnerHTML={{__html: `3. ` + data.sources[2] }} />
					<a href={data.sources[3]} target={`_blank`} className={s.source} dangerouslySetInnerHTML={{__html: `4. ` + data.sources[3] }} />
				</div>

      </div>
      */}

		</section>
)}

export default Main
